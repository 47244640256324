/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Contact { 
    Id?: number;
    Created?: Date | null;
    Modified?: Date | null;
    Deleted?: Date | null;
    CustomerId?: number;
    Initials?: string | null;
    FirstName?: string | null;
    MiddleName?: string | null;
    LastName?: string | null;
    Email?: string | null;
    DateOfBirth?: Date | null;
    Gender?: string | null;
    LanguageId?: number | null;
    AccountType?: number;
    ExternalContactId?: string | null;
    ExternalContactReference?: string | null;
    TelephoneNr1?: string | null;
    TelephoneNr2?: string | null;
    IsInvited?: boolean;
    Primary?: boolean;
    Pwd?: string | null;
    Salt?: string | null;
    Locked?: boolean;
    FailedAttempts?: number;
    ResetPwdKey?: string | null;
    InvitationKey?: string | null;
    InvitedDateTime?: Date | null;
    InviteLastReminderDateTime?: Date | null;
    DoNotInvite?: boolean;
    Active?: boolean;
    TouchedInImport?: boolean;
    ActivationKey?: string | null;
    MustChangePassword?: boolean;
    PreRegisterKey?: string | null;
    Pincode?: string | null;
    SSOId?: string | null;
    IsPlaceholder?: boolean;
}

