/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type LongRunningOperationTypeEnum = 'CacheClear' | 'GenerateReport' | 'CreateOrderImportFile' | 'ProcessOrderImport' | 'CreateReservationImportFile' | 'ProcessReservationImport' | 'OrderImportCreateOrderLines' | 'CreateOrderImportFileForSalesPeriod' | 'CreateEventPlacementForEvent' | 'ApplyPendingVBBChanges' | 'ProcessCustomerImport' | 'ImportCustomerImportFile' | 'ACLEventSync' | 'ExportCustomerImport' | 'ProcessMembershipImport' | 'ImportMembershipImportFile' | 'ExportMembershipImport' | 'CreateNewHardcardBatch' | 'ExportIdentificationReport' | 'ImportReservationImportFile' | 'HandlePassePartoutEvent' | 'CreateProlongation' | 'ProcessProlongationLines' | 'GenerateAclReport' | 'ImportTagFile' | 'ImportDiscountImportFile' | 'ExportDiscountCodes' | 'GenerateDiscountCodes' | 'GenerateHardcardHashes' | 'GeneratePassePartoutEventReport' | 'ExportPhotoBatch' | 'SendBatchToPhocs' | 'ExportTranslationsImport' | 'ImportTranslationsImportFile' | 'ProcessTranslationsImport' | 'CreateOpenReservationOrderExportFileForEvent' | 'ImportOpenReservationImportFile' | 'ProcessOpenReservations' | 'ExportRenewalBatch' | 'ExportMembershipRenewalList' | 'ProcessRenewalFile' | 'ImportLoyaltyTagFile' | 'CreateExternalTicketsImportFile' | 'ExportExternalTicketsImportResult' | 'ImportExternalTicketsImportFile' | 'ProcessImportedRenewalFile' | 'ExportMembershipRenewalListFile' | 'ExportHardcardBatchFile' | 'ImportHardcardBatchFile' | 'ProcessHardcardBatch' | 'DeclineHardcardBatch' | 'GenerateAccountingReport';

export const LongRunningOperationTypeEnum = {
    CacheClear: 'CacheClear' as LongRunningOperationTypeEnum,
    GenerateReport: 'GenerateReport' as LongRunningOperationTypeEnum,
    CreateOrderImportFile: 'CreateOrderImportFile' as LongRunningOperationTypeEnum,
    ProcessOrderImport: 'ProcessOrderImport' as LongRunningOperationTypeEnum,
    CreateReservationImportFile: 'CreateReservationImportFile' as LongRunningOperationTypeEnum,
    ProcessReservationImport: 'ProcessReservationImport' as LongRunningOperationTypeEnum,
    OrderImportCreateOrderLines: 'OrderImportCreateOrderLines' as LongRunningOperationTypeEnum,
    CreateOrderImportFileForSalesPeriod: 'CreateOrderImportFileForSalesPeriod' as LongRunningOperationTypeEnum,
    CreateEventPlacementForEvent: 'CreateEventPlacementForEvent' as LongRunningOperationTypeEnum,
    ApplyPendingVbbChanges: 'ApplyPendingVBBChanges' as LongRunningOperationTypeEnum,
    ProcessCustomerImport: 'ProcessCustomerImport' as LongRunningOperationTypeEnum,
    ImportCustomerImportFile: 'ImportCustomerImportFile' as LongRunningOperationTypeEnum,
    AclEventSync: 'ACLEventSync' as LongRunningOperationTypeEnum,
    ExportCustomerImport: 'ExportCustomerImport' as LongRunningOperationTypeEnum,
    ProcessMembershipImport: 'ProcessMembershipImport' as LongRunningOperationTypeEnum,
    ImportMembershipImportFile: 'ImportMembershipImportFile' as LongRunningOperationTypeEnum,
    ExportMembershipImport: 'ExportMembershipImport' as LongRunningOperationTypeEnum,
    CreateNewHardcardBatch: 'CreateNewHardcardBatch' as LongRunningOperationTypeEnum,
    ExportIdentificationReport: 'ExportIdentificationReport' as LongRunningOperationTypeEnum,
    ImportReservationImportFile: 'ImportReservationImportFile' as LongRunningOperationTypeEnum,
    HandlePassePartoutEvent: 'HandlePassePartoutEvent' as LongRunningOperationTypeEnum,
    CreateProlongation: 'CreateProlongation' as LongRunningOperationTypeEnum,
    ProcessProlongationLines: 'ProcessProlongationLines' as LongRunningOperationTypeEnum,
    GenerateAclReport: 'GenerateAclReport' as LongRunningOperationTypeEnum,
    ImportTagFile: 'ImportTagFile' as LongRunningOperationTypeEnum,
    ImportDiscountImportFile: 'ImportDiscountImportFile' as LongRunningOperationTypeEnum,
    ExportDiscountCodes: 'ExportDiscountCodes' as LongRunningOperationTypeEnum,
    GenerateDiscountCodes: 'GenerateDiscountCodes' as LongRunningOperationTypeEnum,
    GenerateHardcardHashes: 'GenerateHardcardHashes' as LongRunningOperationTypeEnum,
    GeneratePassePartoutEventReport: 'GeneratePassePartoutEventReport' as LongRunningOperationTypeEnum,
    ExportPhotoBatch: 'ExportPhotoBatch' as LongRunningOperationTypeEnum,
    SendBatchToPhocs: 'SendBatchToPhocs' as LongRunningOperationTypeEnum,
    ExportTranslationsImport: 'ExportTranslationsImport' as LongRunningOperationTypeEnum,
    ImportTranslationsImportFile: 'ImportTranslationsImportFile' as LongRunningOperationTypeEnum,
    ProcessTranslationsImport: 'ProcessTranslationsImport' as LongRunningOperationTypeEnum,
    CreateOpenReservationOrderExportFileForEvent: 'CreateOpenReservationOrderExportFileForEvent' as LongRunningOperationTypeEnum,
    ImportOpenReservationImportFile: 'ImportOpenReservationImportFile' as LongRunningOperationTypeEnum,
    ProcessOpenReservations: 'ProcessOpenReservations' as LongRunningOperationTypeEnum,
    ExportRenewalBatch: 'ExportRenewalBatch' as LongRunningOperationTypeEnum,
    ExportMembershipRenewalList: 'ExportMembershipRenewalList' as LongRunningOperationTypeEnum,
    ProcessRenewalFile: 'ProcessRenewalFile' as LongRunningOperationTypeEnum,
    ImportLoyaltyTagFile: 'ImportLoyaltyTagFile' as LongRunningOperationTypeEnum,
    CreateExternalTicketsImportFile: 'CreateExternalTicketsImportFile' as LongRunningOperationTypeEnum,
    ExportExternalTicketsImportResult: 'ExportExternalTicketsImportResult' as LongRunningOperationTypeEnum,
    ImportExternalTicketsImportFile: 'ImportExternalTicketsImportFile' as LongRunningOperationTypeEnum,
    ProcessImportedRenewalFile: 'ProcessImportedRenewalFile' as LongRunningOperationTypeEnum,
    ExportMembershipRenewalListFile: 'ExportMembershipRenewalListFile' as LongRunningOperationTypeEnum,
    ExportHardcardBatchFile: 'ExportHardcardBatchFile' as LongRunningOperationTypeEnum,
    ImportHardcardBatchFile: 'ImportHardcardBatchFile' as LongRunningOperationTypeEnum,
    ProcessHardcardBatch: 'ProcessHardcardBatch' as LongRunningOperationTypeEnum,
    DeclineHardcardBatch: 'DeclineHardcardBatch' as LongRunningOperationTypeEnum,
    GenerateAccountingReport: 'GenerateAccountingReport' as LongRunningOperationTypeEnum
};

