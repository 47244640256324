/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AdditionalDataType = 'CustomerImportFileResult' | 'MembershipImportFileResult' | 'ReservationImportFileResult' | 'TagImportFileResult' | 'DiscountImportFileResult' | 'TranslationImportFileResult' | 'ExternalTicketsImportFileResult';

export const AdditionalDataType = {
    CustomerImportFileResult: 'CustomerImportFileResult' as AdditionalDataType,
    MembershipImportFileResult: 'MembershipImportFileResult' as AdditionalDataType,
    ReservationImportFileResult: 'ReservationImportFileResult' as AdditionalDataType,
    TagImportFileResult: 'TagImportFileResult' as AdditionalDataType,
    DiscountImportFileResult: 'DiscountImportFileResult' as AdditionalDataType,
    TranslationImportFileResult: 'TranslationImportFileResult' as AdditionalDataType,
    ExternalTicketsImportFileResult: 'ExternalTicketsImportFileResult' as AdditionalDataType
};

