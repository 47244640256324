/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ResellerIncrementalBase { 
    Created?: Date;
    Modified?: Date | null;
    Deleted?: Date | null;
    CreatedUtc?: Date;
    ModifiedUtc?: Date | null;
    DeletedUtc?: Date | null;
    Name?: string | null;
    ResellerId?: number;
    Website?: string | null;
    Email?: string | null;
    IBAN?: string | null;
    AllowNewMembers?: boolean | null;
    LocationName?: string | null;
    SecondaryLocationName?: string | null;
    LocationStreet?: string | null;
    LocationStreetNr?: number | null;
    LocationStreetNrAddition?: string | null;
    LocationPostalCode?: string | null;
    LocationCity?: string | null;
    LocationCountry?: string | null;
    LocationCountryISO2?: string | null;
    SecondaryLocationStreet?: string | null;
    SecondaryLocationStreetNr?: number | null;
    SecondaryLocationStreetNrAddition?: string | null;
    SecondaryLocationPostalCode?: string | null;
    SecondaryLocationCity?: string | null;
    SecondaryLocationCountry?: string | null;
    SecondaryLocationCountryISO2?: string | null;
}

