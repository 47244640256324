/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EventOnSaleView { 
    Id?: number;
    StartDateTime?: Date;
    Name?: string | null;
    EventTypeId?: number;
    EventTypeName?: string | null;
    AvailableTickets?: number;
    LocationName?: string | null;
    LocationVersion?: string | null;
    VenueBuildingBlockId?: number;
    EventTypeImage?: string | null;
    ImageUrlAwayTeam?: string | null;
    ImageUrlHomeTeam?: string | null;
    ImageUrlEventType?: string | null;
    ImageUrlEvent?: string | null;
}

