/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DiscountGeneralEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const DiscountGeneralEnum = {
    NUMBER_0: 0 as DiscountGeneralEnum,
    NUMBER_1: 1 as DiscountGeneralEnum,
    NUMBER_2: 2 as DiscountGeneralEnum,
    NUMBER_3: 3 as DiscountGeneralEnum,
    NUMBER_4: 4 as DiscountGeneralEnum,
    NUMBER_5: 5 as DiscountGeneralEnum,
    NUMBER_6: 6 as DiscountGeneralEnum
};

