/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OrderFailureView { 
    SalesGroupId?: number;
    Id?: number;
    Date?: Date;
    CustomerId?: number;
    CustomerName?: string | null;
    CustomerFirstName?: string | null;
    CustomerMiddleName?: string | null;
    CustomerLastName?: string | null;
    CustomerInitials?: string | null;
    readonly CustomerCalculatedName?: string | null;
    CustomerIsIdentified?: boolean;
    CustomerNumber?: string | null;
    FailureReason?: number;
    Details?: string | null;
    AddressLine1?: string | null;
    City?: string | null;
}

