/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CustomerIncrementalBase { 
    Created?: Date;
    Modified?: Date | null;
    Deleted?: Date | null;
    CreatedUtc?: Date;
    ModifiedUtc?: Date | null;
    DeletedUtc?: Date | null;
    CustomerId?: number;
    CustomerNumber?: string | null;
    ExternalCode?: string | null;
    Deceased?: boolean;
    Gender?: string | null;
    CustomerName?: string | null;
    DateOfBirth?: Date | null;
    Firstname?: string | null;
    Middlename?: string | null;
    Lastname?: string | null;
    Street?: string | null;
    StreetNr?: number | null;
    StreetNrAddition?: string | null;
    City?: string | null;
    CountryId?: number;
    CountryIso3?: string | null;
    CountryName?: string | null;
    PostalCode?: string | null;
    TelephoneNr1?: string | null;
    TelephoneNr2?: string | null;
    Email?: string | null;
    SSOId?: string | null;
    CustomerType?: number;
    ExternalTransactionId?: string | null;
}

