/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LongRunningOperation { 
    Id?: number;
    Created?: Date | null;
    Modified?: Date | null;
    Deleted?: Date | null;
    RowVersion?: string | null;
    LastStatusUpdate?: Date;
    ProgressPercentage?: number | null;
    LongRunningOperationStatusId?: number;
    LongRunningOperationTypeId?: number;
    Context?: string | null;
    InitiatedByUserId?: number;
    UserClaims?: string | null;
}

