/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PurchaseRightView { 
    Id?: number;
    AvailableFrom?: Date;
    AvailableTill?: Date;
    AmountPerTicket?: number | null;
    AmountAbsolute?: number | null;
    IsPrimary?: boolean;
    DynamicTagId?: number | null;
    TagId?: number | null;
    OriginPassePartoutId?: number | null;
    TargetPassePartoutId?: number | null;
    TargetEventId?: number | null;
    VenueBuildingBlockId?: number | null;
    TicketTypeId?: number | null;
    TagName?: string | null;
    OriginPassePartoutName?: string | null;
    TargetPassePartoutName?: string | null;
    TargetPassePartoutIsNamed?: boolean;
    TargetEventName?: string | null;
    TargetEventIsNamed?: boolean;
    VenueBuildingBlockName?: string | null;
    SectionShortName?: string | null;
    ParentSectionColor?: string | null;
    SaleCategoryId?: number | null;
    SaleCategoryName?: string | null;
    IsPublic?: boolean;
    UnlimitedAmount?: boolean;
    Transferable?: boolean;
    SalesPeriodId?: number | null;
    IsCrossSell?: boolean;
    TicketTypeName?: string | null;
    InitiativeId?: number | null;
    SalesChannelId?: number | null;
    SalesChannelName?: string | null;
    RestrictToChannel?: boolean;
}

