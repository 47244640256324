/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface HardcardModel { 
    Id?: number;
    Version?: number;
    Barcode?: string | null;
    Created?: Date;
    HardcardStatusId?: number;
    HardcardStatusName?: string | null;
    HardcardTypeName?: string | null;
    ExportToACL?: boolean;
    CreateHardCard?: boolean;
    IsLatest?: boolean;
    LastStatusChanged?: Date | null;
    LastStatusChangeBy?: string | null;
}

