/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SubscriptionView { 
    SubscriptionId?: number;
    SubscriptionLineId?: number;
    CustomerId?: number;
    SubscriptionTypeId?: number;
    BeingModifiedByPendingOrderId?: number | null;
    BeingModifiedByPendingOrderLineId?: number | null;
    OrderId?: number | null;
    OrderLineId?: number | null;
    StartSeason?: number;
    ActiveSeason?: number;
    ActiveSeasonName?: string | null;
    StartSeasonName?: string | null;
    Name?: string | null;
    VisibleFrom?: Date;
    VisibleTill?: Date | null;
    Seasons?: number;
    SeasonsLeft?: number;
    DiscountAbsolute?: number | null;
    DiscountRelative?: number | null;
    PaymentPeriod?: number;
}

