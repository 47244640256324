/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SystemEnum = 'Generic' | 'Backoffice' | 'TicketShop' | 'Distributix' | 'FanManagement';

export const SystemEnum = {
    Generic: 'Generic' as SystemEnum,
    Backoffice: 'Backoffice' as SystemEnum,
    TicketShop: 'TicketShop' as SystemEnum,
    Distributix: 'Distributix' as SystemEnum,
    FanManagement: 'FanManagement' as SystemEnum
};

