import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Injectable } from "@angular/core";
import { AssignmentPolicyService } from '../../api';

@Injectable()
export class AssignmentPolicyValidator {
  constructor(private assignmentPolicyService: AssignmentPolicyService) { }

  public editingObject: any;

  assignmentPolicyCodeTaken = (control: AbstractControl) => {
    return new Promise<ValidationErrors | null>((resolve, reject) => {
      var v = control.value;
      setTimeout(() => {
        if (v === '') {
          resolve(null);
        }

        this.assignmentPolicyService.getAssignmentPolicyByCode(v).subscribe(data => {
          if (data !== null && data !== void 0) {
            if (data.Id != this.editingObject.Id) {
              resolve({ "codetaken": data });
            }
          }
          resolve(null);
        });
      }, 200);
    });
  }
}