/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ModificationActionEnum = 1 | 2 | 3 | 4;

export const ModificationActionEnum = {
    NUMBER_1: 1 as ModificationActionEnum,
    NUMBER_2: 2 as ModificationActionEnum,
    NUMBER_3: 3 as ModificationActionEnum,
    NUMBER_4: 4 as ModificationActionEnum
};

