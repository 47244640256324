/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DiscountGeneralModel { 
    Active?: boolean;
    ValidFrom?: Date | null;
    ValidTill?: Date | null;
    DiscountType?: number;
    DiscountCode?: string | null;
    AmountPerc?: number;
    Id?: number;
    MaximumCount?: number | null;
    HasMaximumCount?: boolean;
    HasMaximumCountOrder?: boolean;
    MaximumForOrder?: number | null;
    NonStackable?: boolean;
}

