/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PendingLineArgs { 
    Column?: number | null;
    NumberOfTickets?: number;
    Row?: number | null;
    TicketTypeId?: number;
    ContingentId?: number | null;
    PriceVariantId?: number | null;
    MarketplacePrice?: number | null;
    MarketplaceBundled?: boolean;
    SectorId?: number | null;
    NumberOfMdps?: number | null;
}

