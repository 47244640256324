/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SystemInfoModel { 
    DBIsUpToDate?: boolean;
    LatestAppliedMigration?: string | null;
    NumberOfConfigSettings?: number;
    BuildDateTime?: string | null;
    BuildType?: string | null;
    EnvironmentCode?: string | null;
    OS?: string | null;
    SystemTime?: string | null;
    Version?: string | null;
    ACLIsUpToDate?: boolean;
}

