/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OrderPaymentHistoryModel { 
    Description?: string | null;
    Amount?: number | null;
    Type?: string | null;
    ReceivedTimeStamp?: Date | null;
    PaymentTimeStamp?: Date | null;
    CustomerId?: number;
    Handled?: boolean;
    PaymentStatus?: number;
    OrderId?: number;
    PaymentReference?: string | null;
    PaymentMethodName?: string | null;
    PaymentTypeId?: number | null;
}

