/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InvoiceBatchOrderLinesView { 
    InvoiceId?: number;
    InvoiceBatchId?: number;
    AmountExVat?: number;
    OrderId?: number;
    CustomerId?: number;
    VAT?: number;
    AmountInclVat?: number;
    VATRateId?: number;
    TicketTypeId?: number;
    ProductType?: number;
    RowNumber?: string | null;
    SeatNumber?: string | null;
    PriceVariationName?: string | null;
    TicketTypeName?: string | null;
    ParentSectionName?: string | null;
    CalculatedDiscount?: number;
    TicketCustomer?: string | null;
    MembershipName?: string | null;
    PassePartoutName?: string | null;
    EventName?: string | null;
    EventStartDateTime?: Date | null;
}

