/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MatchDayPassView { 
    Id?: number;
    ShortCode?: string | null;
    Name?: string | null;
    ImageId?: number | null;
    LedgerAccount?: string | null;
    IsUniquelyAssigned?: boolean;
    IsDisplayedInShopHeader?: boolean;
    IsOnSale?: boolean;
    OnSaleFrom?: Date | null;
    OnSaleTo?: Date | null;
    Stock?: number;
    IsActive?: boolean;
    MaxPoints?: number;
    MaxTickets?: number;
    IsOneTicketPerMatch?: boolean;
}

