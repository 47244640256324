/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PreAuthResultEnum = 'OK' | 'TFA' | 'Failed' | 'NotAllowed';

export const PreAuthResultEnum = {
    Ok: 'OK' as PreAuthResultEnum,
    Tfa: 'TFA' as PreAuthResultEnum,
    Failed: 'Failed' as PreAuthResultEnum,
    NotAllowed: 'NotAllowed' as PreAuthResultEnum
};

