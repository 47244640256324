/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ActionTypeEnum = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23;

export const ActionTypeEnum = {
    NUMBER_1: 1 as ActionTypeEnum,
    NUMBER_2: 2 as ActionTypeEnum,
    NUMBER_3: 3 as ActionTypeEnum,
    NUMBER_4: 4 as ActionTypeEnum,
    NUMBER_5: 5 as ActionTypeEnum,
    NUMBER_6: 6 as ActionTypeEnum,
    NUMBER_7: 7 as ActionTypeEnum,
    NUMBER_8: 8 as ActionTypeEnum,
    NUMBER_9: 9 as ActionTypeEnum,
    NUMBER_10: 10 as ActionTypeEnum,
    NUMBER_11: 11 as ActionTypeEnum,
    NUMBER_12: 12 as ActionTypeEnum,
    NUMBER_13: 13 as ActionTypeEnum,
    NUMBER_14: 14 as ActionTypeEnum,
    NUMBER_15: 15 as ActionTypeEnum,
    NUMBER_16: 16 as ActionTypeEnum,
    NUMBER_17: 17 as ActionTypeEnum,
    NUMBER_18: 18 as ActionTypeEnum,
    NUMBER_19: 19 as ActionTypeEnum,
    NUMBER_20: 20 as ActionTypeEnum,
    NUMBER_21: 21 as ActionTypeEnum,
    NUMBER_22: 22 as ActionTypeEnum,
    NUMBER_23: 23 as ActionTypeEnum
};

