import { FullscreenOverlayContainer } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmService, EnvironmentService, HttpLoaderService, ListViewSelectionMode, PagedResult, SearchDataRequest } from '@tymes4-shared';
import * as moment from 'moment';
import { BASE_BO_CONFIG } from '../../../../injectors';
import { BackofficeConfigModel, CustomerService, CustomerViewPagedResult } from '../../../api';
import { EditCustomerFormComponent } from '../../../dialogs/edit-customer-form/edit-customer-form.component';
import { AppUser } from '../../../models/app-user';
import { AuthService } from '../../../services/auth.service';
import { PersistedSearchService } from '../../../services/persisted-search.service';
import {CustomerBarcodeSearchDialogComponent} from "../customer-barcode-search-dialog/customer-barcode-search-dialog.component";
import { ApplicationModuleHelperService } from '../../../services/application-module.service';


interface RelationSearchFilter {
  FirstName: string;
  MiddleName: string;
  LastName: string;
  CustomerNumber: string;
  Email: string;
  PostalCode: string;
  StreetNr: string;
  Street: string;
  City: string;
  DateOfBirth: string;
  CompanyName: string;
}

interface Customer {
  Addressline1: string;
  Channels: string;
  City: string;
  Country: string;
  CountryIso3: string;
  CustomerNumber: string;
  CustomerType: number;
  DateOfBirth: Date;
  Email: string;
  ExternalId: string;
  Id: number;
  Name: string;
  PostalCode: string;
  Street: string;
  StreetNr: string;
  TelephoneNr: string;
}

@Component({
  selector: 't4-customer-search',
  templateUrl: 'customer-search.component.html'
})


export class CustomerSearchComponent implements OnInit, AfterViewInit {


  @ViewChild('listVw') listVw;
  @Output() onCustomerSelect = new EventEmitter<object>();
  @Input() viewMode: string = 'full'; //full | minimal | cash-register

  persistKey = 'customer-search';
  loggedInUser?: AppUser;
  //Display options
  useFilter = false;
  allowEdit = true;
  loadDataOnOpen = false;
  showAddress = true;
  isMultiSelect = false;
  allowAdd = true;
  //End Display options

  selectedCustomers = null;
  isManagedFromExtSource = false;
  totalCustomerCount: number;
  searchText: string;
  appliedFilter: any = null;

  isIbaActive: boolean = false;

  public listOptions = {
    filterRequired: true,
    search: false,
    showBarcodeSearch: true,
    pageSize: 12,
    selectionMode: ListViewSelectionMode.DirectSelect,
    fixedTableLayout: true,
    headers: [
      {sortField: null, label: 'RELATIONS.OVERVIEW.LISTVIEW.HEADER.CUSTOMERNUMBER', width: 140},
      {sortField: 'Name', label: 'RELATIONS.OVERVIEW.LISTVIEW.HEADER.NAME', isDefaultSort: 'asc', width: 200},
      {sortField: null, label: 'RELATIONS.OVERVIEW.LISTVIEW.HEADER.AGE', width: 140},
      {sortField: null, label: 'RELATIONS.OVERVIEW.LISTVIEW.HEADER.COMPANYNAME', width: 200},
      {sortField: null, label: 'RELATIONS.OVERVIEW.LISTVIEW.HEADER.EMAIL', width: 200}
    ]
  };

  public isInArchiveMode = this.authService.isInArchiveMode();

  constructor(
    private customerService: CustomerService,
    private router: Router,
    private applicationModuleHelperService: ApplicationModuleHelperService,
    private confirmService: ConfirmService,
    private loader: HttpLoaderService,
    private dialog: MatDialog,
    private persistedSearchService: PersistedSearchService,
    private translate: TranslateService,
    private authService: AuthService,
    @Inject(BASE_BO_CONFIG) public config: BackofficeConfigModel,) {
  }

  ngOnInit() {
    this.loggedInUser = this.authService.getLoggedInUser();
    let pageSize = 12;

    //setup the listoptions etc
    switch (this.viewMode) {
      case 'full':
        break;

      case 'minimal':
        this.allowEdit = false;
        break;

      case 'cash-register':
        pageSize = 9;
        this.allowEdit = false;
        this.loadDataOnOpen = true;
        this.showAddress = false;
        this.isMultiSelect = true;
        this.allowAdd = true;
        break;
    }

    if (this.isMultiSelect === true) {
      (this.listOptions as any).selectionMode = ListViewSelectionMode.MultiSelect;
    }

    this.isManagedFromExtSource = this.config.companiesFromExternalSource;
    this.searchText = '';
    this.listOptions.pageSize = pageSize;

    if (this.showAddress === true) {
      this.listOptions.headers.push({sortField: null, label: 'RELATIONS.OVERVIEW.LISTVIEW.HEADER.ADDRESS', width: 200});
    }
    if (this.allowEdit === true) {
      this.listOptions.headers.push({ sortField: null, label: '', width: 100});
    }

    this.applicationModuleHelperService.getActiveModules().subscribe((activeModules) => {
      if (activeModules !== null) {
        this.isIbaActive = activeModules.some((module) => module.toUpperCase() == 'IBA')
      }
    })

  }

  ngAfterViewInit() {
    setTimeout(() => {
      let persistedFilter = this.persistedSearchService.getPersistedSearch(this.persistKey);

      if (persistedFilter != null) {

        this.listOptions.filterRequired = true; //do not reload after the applied filter
        this.listVw.reloadEarlierFilter(persistedFilter.searchFilters, persistedFilter.pageNr, persistedFilter.sortField, persistedFilter.sortAsc);
        //let the listview know that it should not load automatically
        // this.listOptions.filterRequired = true;
        // this.appliedFilter = persistedFilter.searchFilters;
        // this.updateFilter(persistedFilter);


      } else {
        this.listOptions.filterRequired = !this.loadDataOnOpen;
      }
    });


  }

  actionClick(action: 'add' | 'edit' | 'delete' | 'rowclick', customer: Customer = null) {

    switch (action) {
      case 'add':
        this.openDialog();
        break;
      case 'edit':
        this.openDialog(customer);
        break;
      case 'delete':
        this.openConfirmDialog(customer);
        break;
      case 'rowclick':

      if (this.viewMode !== 'full') {
        this.onCustomerSelect.emit( [ customer ]);
      }
      else {
        this.router.navigate(['relations/customers/details', customer.Id]);
      }

      break;
    }
  }

  onDataRequest(e: SearchDataRequest) {
    if (e.barcodeSearch === true) {
      this.customerPickupBarcode(e);
    }
    else {
      const searchFilters = {...e.searchFilters} as RelationSearchFilter;
      const isNullOrWhitespace = (str: string) => (str === null || str === undefined || (typeof (str) === 'string' && str.trim().length === 0));

      if (!isNullOrWhitespace(searchFilters.DateOfBirth))
        searchFilters.DateOfBirth = moment(searchFilters.DateOfBirth, 'DD-MM-YYYY').toISOString(true);

      this.appliedFilter = searchFilters;
      this.updateFilter(e);
    }
  }

  fetchCustomers(sdr: SearchDataRequest) {


    this.persistedSearchService.setPersistedSearch(this.persistKey, sdr); //store for later

    this.loader.open();
    this.customerService.searchCustomers(this.searchText, sdr.pageNr, this.listOptions.pageSize, sdr.sortField, sdr.sortAsc, { ['filter']: 'true' }).subscribe((resp: CustomerViewPagedResult) => {
      this.loader.close();
      this.listVw.Data = resp;
    });
  }

  OnItemSelectionChange($event){
      //tell the parent we are done
      this.selectedCustomers = $event;
      this.onCustomerSelect.emit(this.selectedCustomers);
  }


  setSelection(selection: Array<{Id: number}>) {
    this.selectedCustomers = selection;
    this.listVw.setSelection(selection);
  }

  updateFilter(sdr: SearchDataRequest) {
    if (this.appliedFilter !== void 0 && this.appliedFilter !== null) {
      this.useFilter = true;
      this.searchText = JSON.stringify(this.appliedFilter);
    } else {
      this.useFilter = false;
      this.searchText = sdr.searchText;
    }

    this.fetchCustomers(sdr);
  }

  getCombinedAddress(customer) {

    if (customer == null) return '';
    return customer.Addressline1 + ', ' + customer.PostalCode + ' ' + customer.City;
  }


  openDialog(customer: Customer = null) {

    const isNew = customer === null;
    let title = isNew ? this.translate.instant('RELATIONS.EDITDIALOG.TITLE.NEW' ) : this.translate.instant('RELATIONS.EDITDIALOG.TITLE.EDIT') + ": " + customer.Name;
    let dialogRef: MatDialogRef<any> = this.dialog.open(EditCustomerFormComponent, {
      maxWidth: '100%',
      maxHeight: '100%',
      disableClose: true,
      data: { title: title, payload: customer ? customer.Id : customer, isNew: isNew }
    })
    dialogRef.afterClosed()
      .subscribe(id => {
          if (id) {
            this.listVw.refreshData();
          } else {
            return;
          }
        }
      )
  }

  openConfirmDialog(customer: Customer) {

    this.customerService.customerHasActiveSalesItems(customer.Id).subscribe(hasActiveSalesItems => {

      if (hasActiveSalesItems === true) {
        //not possible
        this.confirmService.confirm({
          title: this.translate.instant('GENERIC.CONFIRM.ERRORS.TITLE.DELETION-NOT-POSSIBLE'),
          message:  this.translate.instant('GENERIC.CONFIRM.ERRORS.MESSAGE.DELETION-NOT-POSSIBLE-IN-USE'),
          okonly: true
        }).subscribe((confirmed: boolean) => {
        });
      } else {
        this.confirmService.confirm({
          title: this.translate.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
          message: this.translate.instant('GENERIC.CONFIRM.WARNING.MESSAGE.CONFIRM-DELETE'),
          okonly: false
        }).subscribe((confirmed: boolean) => {
          this.loader.open();
          if (confirmed === true) {

            this.customerService.deleteCustomer(customer.Id).subscribe((data: any) => {
              //close this dialog and pass the newly added id to the called
              this.listVw.refreshData();
              this.loader.close();
            });
          } else {
            this.loader.close();
          }
        });
      }
    });
  }

  customerPickupBarcode(sdr: SearchDataRequest) {

    let title = this.translate.instant('RELATION.OVERVIEW.BARCODEPOPUP.TITLE');
    let dialogRef: MatDialogRef<any> = this.dialog.open(CustomerBarcodeSearchDialogComponent, {
      width: '500px',
      maxWidth: '98vw',
      disableClose: false,
      data: { }
    });
    dialogRef.afterClosed()
      .subscribe(customer => {
          if (customer) {
            sdr.searchFilters.CustomerNumber = customer.CustomerNumber;
            this.persistedSearchService.setPersistedSearch(this.persistKey, sdr); //store for later
            if (!this.isMultiSelect) {
              if (this.viewMode !== 'full') {
                this.onCustomerSelect.emit([customer]);
              } else {
                this.router.navigate(['relations/customers/details', customer.Id]);
              }
            } else {
              let check = true
              for (let x of this.selectedCustomers) {
                if (x.CustomerNumber == customer.CustomerNumber) {
                  check = false;
                }
              }
              if (check) {
                this.selectedCustomers.push(customer);
                this.listVw.setSelection(this.selectedCustomers);
              }
              const d : PagedResult =
              {
                TotalRecords: 1,
                Records: [customer],
                ResultCountCapped: false

              }

              this.listVw.Data = d;
            }

          }

        }
      );

  }

  showAge(age: number, customerType: number){
    if(customerType == 2){
      if(age){
        return age;
      }
      else{
        return this.translate.instant('RELATION.OVERVIEW.AGE.UNKNOWN');
      }
    }
    else{
      return ""
    }
  }
}

