
<t4-list-view #listVw [options]="listOptions" (onItemSelectionChange)="OnItemSelectionChange($event)"  (onDirectSelection)="actionClick('rowclick', $event)" (onDataRequest)="onDataRequest($event)">
  <t4-date-time-control [beforeToday]="false" [labelDate]="'CUSTOM.BUTTON.STATE.FORM.FROM-DATE'" [labelTime]="'CUSTOM.BUTTON.STATE.FORM.FROM-TIME'"></t4-date-time-control>

    <ng-container *t4ListActions>
      <button *ngIf="allowAdd === true && !isInArchiveMode" (click)="actionClick('add')" class="btn btn-primary border" type="button"><i class="far fa-plus-square"></i>{{ 'GENERIC.BUTTON.ADDNEW' | translate}}</button>
    </ng-container>

  <ng-container *t4ListItem="let item">

    <td class= "company-info" data-toggle="tooltip" data-placement="bottom" title="{{ item.ExternalId }}">{{ item.CustomerNumber }} / {{item.ExternalId | replaceOnEmpty}}</td>
    <td class= "company-info" data-toggle="tooltip" data-placement="bottom" title="{{ item.CalculatedName }}"><t4-customer-identification-control [isIbaActive]="isIbaActive" [customer]="item"></t4-customer-identification-control></td>
    <td class= "company-info" data-toggle="tooltip" data-placement="bottom" title="{{ item.Age }}">{{ showAge(item.Age, item.CustomerType) }}</td>
    <td class= "company-info" data-toggle="tooltip" data-placement="bottom" title="{{ item.Name }}">{{item.CustomerType == 1 ? item.Name : null | replaceOnEmpty }}</td>
    <td class= "company-info" data-toggle="tooltip" data-placement="bottom" title="{{ item.Email }}">{{item.Email}}</td>
    <td *ngIf="showAddress === true" class= "company-info" data-toggle="tooltip" data-placement="bottom" title="{{ getCombinedAddress(item) }}">{{getCombinedAddress(item)}}</td>

    <td class="grid-action" *ngIf="allowEdit">

      <button *ngIf="!isInArchiveMode" (click)="this.actionClick('edit', item)" type="button" class="btn btn-grid btn-round mdi mdi-info mdi-lead-pencil"></button>
      <button *ngIf="!isInArchiveMode" (click)="this.actionClick('delete', item)" type="button" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline"></button>

    </td>


  </ng-container>

    <ng-container *t4ItemFilter="let kvp=kvp">


      <div class="row">
        <div class="col-lg-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <span class="input-group-text mdi mdi-account"></span>
            </div>
            <input type="text" class="form-control form-control-filter mr-1" placeholder="{{ 'RELATIONS.OVERVIEW.FILTERS.LABEL.FIRSTNAME' | translate }}" (keyup.enter)='listVw.updateFilter($event)' name="FirstName" [(ngModel)]="kvp.FirstName" autocomplete="off">
            <input type="text" class="form-control form-control-filter mr-1" placeholder="{{ 'RELATIONS.OVERVIEW.FILTERS.LABEL.MIDDLENAME' | translate }}" (keyup.enter)='listVw.updateFilter($event)' name="MiddleName" [(ngModel)]="kvp.MiddleName" autocomplete="off" style="max-width: 50px;">
          </div>
        </div>

        <div class="col-lg-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <span class="input-group-text mdi mdi-account"></span>
            </div>

            <input type="text" class="form-control form-control-filter mr-1" placeholder="{{ 'RELATIONS.OVERVIEW.FILTERS.LABEL.LASTNAME' | translate }}" (keyup.enter)='listVw.updateFilter($event)'  name="LastName" [(ngModel)]="kvp.LastName" autocomplete="off">


          </div>
        </div>

        <div class="col-lg-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <span class="input-group-text mdi mdi-calendar-heart"></span>
            </div>
            <input type="text" class="form-control form-control-filter mr-1" placeholder="{{ 'RELATIONS.OVERVIEW.FILTERS.LABEL.DATEOFBIRTH' | translate }}" style="width: 10px; display: inline-block" datepicker [beforeToday]="true" [(ngModel)]="kvp.DateOfBirth" name="DateOfBirth" autocomplete="off">
          </div>
        </div>

        <div class="col-lg-6">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <span class="input-group-text mdi mdi-home-variant-outline"></span>
            </div>
            <input type="text" class="form-control form-control-filter mr-1" placeholder="{{ 'RELATIONS.OVERVIEW.FILTERS.LABEL.STREET' | translate }}"  name="Street" (keyup.enter)='listVw.updateFilter($event)' [(ngModel)]="kvp.Street"  autocomplete="off">
            <input type="text" class="form-control form-control-filter mr-1" placeholder="{{ 'RELATIONS.OVERVIEW.FILTERS.LABEL.STREETNR' | translate }}" name="StreetNr" (keyup.enter)='listVw.updateFilter($event)' [(ngModel)]="kvp.StreetNr" autocomplete="off">
            <input type="text" class="form-control form-control-filter mr-1" placeholder="{{ 'RELATIONS.OVERVIEW.FILTERS.LABEL.CITY' | translate }}" name="City" (keyup.enter)='listVw.updateFilter($event)' [(ngModel)]="kvp.City" autocomplete="off">
            <input type="text" class="form-control form-control-filter mr-1" placeholder="{{ 'RELATIONS.OVERVIEW.FILTERS.LABEL.POSTALCODE' | translate }}" name="Postalcode" (keyup.enter)='listVw.updateFilter($event)' [(ngModel)]="kvp.PostalCode" autocomplete="off">
          </div>
        </div>

      </div>

      <div class="row mt-1">
        <div class="col-lg-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <span class="input-group-text mdi mdi-email"></span>
            </div>
            <input type="text" class="form-control form-control-filter mr-1" name="Email" placeholder="{{ 'RELATIONS.OVERVIEW.FILTERS.LABEL.EMAIL' | translate }}" (keyup.enter)='listVw.updateFilter($event)' [(ngModel)]="kvp.Email" autocomplete="off">
          </div>
        </div>

        <div class="col-lg-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <span class="input-group-text mdi mdi-office-building"></span>
            </div>
            <input type="text" class="form-control form-control-filter mr-1" name="CompanyName" placeholder="{{ 'RELATIONS.OVERVIEW.FILTERS.LABEL.COMPANYNAME' | translate }}" (keyup.enter)='listVw.updateFilter($event)' [(ngModel)]="kvp.CompanyName" autocomplete="off">
          </div>

        </div>
        <div class="col-lg-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <span class="input-group-text mdi mdi-account-card-details"></span>
            </div>
            <input type="text" class="form-control form-control-filter mr-1" name="CustomerNumber" placeholder="{{ 'RELATIONS.OVERVIEW.FILTERS.LABEL.CUSTOMERNUMBER' | translate }}" (keyup.enter)='listVw.updateFilter($event)' [(ngModel)]="kvp.CustomerNumber" autocomplete="off">
          </div>

        </div>

        <div class="col-lg-4">
        </div>

      </div>

    </ng-container>

</t4-list-view>
