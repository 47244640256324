/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';
import { environment } from 'environments/environment';

// @ts-ignore
import { AllEventsForPassePartoutViewPagedResult } from '../model/allEventsForPassePartoutViewPagedResult';
// @ts-ignore
import { Country } from '../model/country';
// @ts-ignore
import { HardcardModel } from '../model/hardcardModel';
// @ts-ignore
import { LongRunningOperationModel } from '../model/longRunningOperationModel';
// @ts-ignore
import { PassePartout } from '../model/passePartout';
// @ts-ignore
import { PassePartoutDeliveryMethodView } from '../model/passePartoutDeliveryMethodView';
// @ts-ignore
import { PassePartoutDetail } from '../model/passePartoutDetail';
// @ts-ignore
import { PassePartoutDetailPagedResult } from '../model/passePartoutDetailPagedResult';
// @ts-ignore
import { PassePartoutEventValidation } from '../model/passePartoutEventValidation';
// @ts-ignore
import { PassePartoutModel } from '../model/passePartoutModel';
// @ts-ignore
import { PassePartoutOnSaleViewPagedResult } from '../model/passePartoutOnSaleViewPagedResult';
// @ts-ignore
import { PassePartoutPagedResult } from '../model/passePartoutPagedResult';
// @ts-ignore
import { PassePartoutPositionView } from '../model/passePartoutPositionView';
// @ts-ignore
import { PassePartoutPriceListView } from '../model/passePartoutPriceListView';
// @ts-ignore
import { PassePartoutStoreArgs } from '../model/passePartoutStoreArgs';
// @ts-ignore
import { PaymentMethodModel } from '../model/paymentMethodModel';
// @ts-ignore
import { SeasonPassePartoutCount } from '../model/seasonPassePartoutCount';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class PassePartoutService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param customerPassePartoutId 
     * @param epId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canRemovePosition(customerPassePartoutId: number, epId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<boolean>;
    public canRemovePosition(customerPassePartoutId: number, epId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<boolean>>;
    public canRemovePosition(customerPassePartoutId: number, epId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<boolean>>;
    public canRemovePosition(customerPassePartoutId: number, epId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (customerPassePartoutId === null || customerPassePartoutId === undefined) {
            throw new Error('Required parameter customerPassePartoutId was null or undefined when calling canRemovePosition.');
        }
        if (epId === null || epId === undefined) {
            throw new Error('Required parameter epId was null or undefined when calling canRemovePosition.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('PassePartoutService.canRemovePosition - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/PassePartout/can-remove-position/${encodeURIComponent(String(customerPassePartoutId))}/${encodeURIComponent(String(epId))}`;
        return this.httpClient.get<boolean>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param passePartoutStoreArgs 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPassepartout(passePartoutStoreArgs?: PassePartoutStoreArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<number>;
    public createPassepartout(passePartoutStoreArgs?: PassePartoutStoreArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<number>>;
    public createPassepartout(passePartoutStoreArgs?: PassePartoutStoreArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<number>>;
    public createPassepartout(passePartoutStoreArgs?: PassePartoutStoreArgs, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('PassePartoutService.createPassepartout - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/PassePartout/createpassepartout`;
        return this.httpClient.post<number>(`${this.configuration.basePath}${localVarPath}`,
            passePartoutStoreArgs,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param pptId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePassePartout(pptId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public deletePassePartout(pptId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public deletePassePartout(pptId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public deletePassePartout(pptId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (pptId === null || pptId === undefined) {
            throw new Error('Required parameter pptId was null or undefined when calling deletePassePartout.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('PassePartoutService.deletePassePartout - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/PassePartout/${encodeURIComponent(String(pptId))}`;
        return this.httpClient.delete<any>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param customerPassePartoutId 
     * @param eventPlacementId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePosition(customerPassePartoutId: number, eventPlacementId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public deletePosition(customerPassePartoutId: number, eventPlacementId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public deletePosition(customerPassePartoutId: number, eventPlacementId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public deletePosition(customerPassePartoutId: number, eventPlacementId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (customerPassePartoutId === null || customerPassePartoutId === undefined) {
            throw new Error('Required parameter customerPassePartoutId was null or undefined when calling deletePosition.');
        }
        if (eventPlacementId === null || eventPlacementId === undefined) {
            throw new Error('Required parameter eventPlacementId was null or undefined when calling deletePosition.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('PassePartoutService.deletePosition - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/PassePartout/${encodeURIComponent(String(customerPassePartoutId))}/${encodeURIComponent(String(eventPlacementId))}`;
        return this.httpClient.delete<any>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCurrentFuturePassePartouts(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<PassePartout>>;
    public getCurrentFuturePassePartouts(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<PassePartout>>>;
    public getCurrentFuturePassePartouts(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<PassePartout>>>;
    public getCurrentFuturePassePartouts(requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('PassePartoutService.getCurrentFuturePassePartouts - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/PassePartout/currfuturepassepartouts`;
        return this.httpClient.get<Array<PassePartout>>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNewPassePartoutPaymentMethods(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<PaymentMethodModel>>;
    public getNewPassePartoutPaymentMethods(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<PaymentMethodModel>>>;
    public getNewPassePartoutPaymentMethods(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<PaymentMethodModel>>>;
    public getNewPassePartoutPaymentMethods(requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('PassePartoutService.getNewPassePartoutPaymentMethods - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/PassePartout/paymentmethods`;
        return this.httpClient.get<Array<PaymentMethodModel>>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param code 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPPTByCode(code?: string, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<PassePartout>;
    public getPPTByCode(code?: string, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<PassePartout>>;
    public getPPTByCode(code?: string, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<PassePartout>>;
    public getPPTByCode(code?: string, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (code !== undefined && code !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>code, 'code');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('PassePartoutService.getPPTByCode - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/PassePartout/getbycode`;
        return this.httpClient.get<PassePartout>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param customerPassePartoutId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPPTHardcards(customerPassePartoutId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<HardcardModel>>;
    public getPPTHardcards(customerPassePartoutId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<HardcardModel>>>;
    public getPPTHardcards(customerPassePartoutId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<HardcardModel>>>;
    public getPPTHardcards(customerPassePartoutId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (customerPassePartoutId === null || customerPassePartoutId === undefined) {
            throw new Error('Required parameter customerPassePartoutId was null or undefined when calling getPPTHardcards.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('PassePartoutService.getPPTHardcards - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/PassePartout/${encodeURIComponent(String(customerPassePartoutId))}/hardcards`;
        return this.httpClient.get<Array<HardcardModel>>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param passePartoutId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPassePartoutDeliveryMethods(passePartoutId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<PassePartoutDeliveryMethodView>>;
    public getPassePartoutDeliveryMethods(passePartoutId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<PassePartoutDeliveryMethodView>>>;
    public getPassePartoutDeliveryMethods(passePartoutId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<PassePartoutDeliveryMethodView>>>;
    public getPassePartoutDeliveryMethods(passePartoutId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (passePartoutId === null || passePartoutId === undefined) {
            throw new Error('Required parameter passePartoutId was null or undefined when calling getPassePartoutDeliveryMethods.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('PassePartoutService.getPassePartoutDeliveryMethods - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/PassePartout/${encodeURIComponent(String(passePartoutId))}/deliverymethods`;
        return this.httpClient.get<Array<PassePartoutDeliveryMethodView>>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPassePartoutDetail(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<PassePartoutDetail>;
    public getPassePartoutDetail(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<PassePartoutDetail>>;
    public getPassePartoutDetail(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<PassePartoutDetail>>;
    public getPassePartoutDetail(id: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getPassePartoutDetail.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('PassePartoutService.getPassePartoutDetail - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/PassePartout/${encodeURIComponent(String(id))}/details`;
        return this.httpClient.get<PassePartoutDetail>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPassePartoutPaymentMethods(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<PaymentMethodModel>>;
    public getPassePartoutPaymentMethods(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<PaymentMethodModel>>>;
    public getPassePartoutPaymentMethods(id: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<PaymentMethodModel>>>;
    public getPassePartoutPaymentMethods(id: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getPassePartoutPaymentMethods.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('PassePartoutService.getPassePartoutPaymentMethods - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/PassePartout/paymentmethods/${encodeURIComponent(String(id))}`;
        return this.httpClient.get<Array<PaymentMethodModel>>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param customerPassePartoutId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPassePartoutPositions(customerPassePartoutId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<PassePartoutPositionView>>;
    public getPassePartoutPositions(customerPassePartoutId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<PassePartoutPositionView>>>;
    public getPassePartoutPositions(customerPassePartoutId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<PassePartoutPositionView>>>;
    public getPassePartoutPositions(customerPassePartoutId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (customerPassePartoutId === null || customerPassePartoutId === undefined) {
            throw new Error('Required parameter customerPassePartoutId was null or undefined when calling getPassePartoutPositions.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('PassePartoutService.getPassePartoutPositions - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/PassePartout/${encodeURIComponent(String(customerPassePartoutId))}/positions`;
        return this.httpClient.get<Array<PassePartoutPositionView>>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param passePartoutId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPassePartoutPriceList(passePartoutId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<PassePartoutPriceListView>>;
    public getPassePartoutPriceList(passePartoutId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<PassePartoutPriceListView>>>;
    public getPassePartoutPriceList(passePartoutId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<PassePartoutPriceListView>>>;
    public getPassePartoutPriceList(passePartoutId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (passePartoutId === null || passePartoutId === undefined) {
            throw new Error('Required parameter passePartoutId was null or undefined when calling getPassePartoutPriceList.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('PassePartoutService.getPassePartoutPriceList - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/PassePartout/${encodeURIComponent(String(passePartoutId))}/pricelists`;
        return this.httpClient.get<Array<PassePartoutPriceListView>>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param seasonId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPassePartoutsInSeason(seasonId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<PassePartoutModel>>;
    public getPassePartoutsInSeason(seasonId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<PassePartoutModel>>>;
    public getPassePartoutsInSeason(seasonId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<PassePartoutModel>>>;
    public getPassePartoutsInSeason(seasonId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (seasonId === null || seasonId === undefined) {
            throw new Error('Required parameter seasonId was null or undefined when calling getPassePartoutsInSeason.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('PassePartoutService.getPassePartoutsInSeason - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/PassePartout/${encodeURIComponent(String(seasonId))}/in-season`;
        return this.httpClient.get<Array<PassePartoutModel>>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPastPassePartouts(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<PassePartout>>;
    public getPastPassePartouts(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<PassePartout>>>;
    public getPastPassePartouts(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<PassePartout>>>;
    public getPastPassePartouts(requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('PassePartoutService.getPastPassePartouts - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/PassePartout/preceding`;
        return this.httpClient.get<Array<PassePartout>>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param searchText 
     * @param page 
     * @param perPage 
     * @param sort 
     * @param ascending 
     * @param filters 
     * @param parentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public passePartoutSalesSearch(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<PassePartoutOnSaleViewPagedResult>;
    public passePartoutSalesSearch(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<PassePartoutOnSaleViewPagedResult>>;
    public passePartoutSalesSearch(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<PassePartoutOnSaleViewPagedResult>>;
    public passePartoutSalesSearch(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchText !== undefined && searchText !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchText, 'SearchText');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'Page');
        }
        if (perPage !== undefined && perPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>perPage, 'PerPage');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'Sort');
        }
        if (ascending !== undefined && ascending !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ascending, 'Ascending');
        }
        if (filters !== undefined && filters !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filters, 'Filters');
        }
        if (parentId !== undefined && parentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>parentId, 'ParentId');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('PassePartoutService.passePartoutSalesSearch - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/PassePartout/salessearch`;
        return this.httpClient.get<PassePartoutOnSaleViewPagedResult>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param pptId 
     * @param seasonId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pptEventValidation(pptId: number, seasonId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<PassePartoutEventValidation>>;
    public pptEventValidation(pptId: number, seasonId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<PassePartoutEventValidation>>>;
    public pptEventValidation(pptId: number, seasonId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<PassePartoutEventValidation>>>;
    public pptEventValidation(pptId: number, seasonId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (pptId === null || pptId === undefined) {
            throw new Error('Required parameter pptId was null or undefined when calling pptEventValidation.');
        }
        if (seasonId === null || seasonId === undefined) {
            throw new Error('Required parameter seasonId was null or undefined when calling pptEventValidation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('PassePartoutService.pptEventValidation - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/PassePartout/eventvalidation/${encodeURIComponent(String(pptId))}/${encodeURIComponent(String(seasonId))}`;
        return this.httpClient.get<Array<PassePartoutEventValidation>>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param seasonId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pptGetBySeason(seasonId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<SeasonPassePartoutCount>>;
    public pptGetBySeason(seasonId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<SeasonPassePartoutCount>>>;
    public pptGetBySeason(seasonId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<SeasonPassePartoutCount>>>;
    public pptGetBySeason(seasonId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (seasonId === null || seasonId === undefined) {
            throw new Error('Required parameter seasonId was null or undefined when calling pptGetBySeason.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('PassePartoutService.pptGetBySeason - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/PassePartout/getbyseason/${encodeURIComponent(String(seasonId))}`;
        return this.httpClient.get<Array<SeasonPassePartoutCount>>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pptGetForQueryEditor(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<PassePartoutModel>>;
    public pptGetForQueryEditor(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<PassePartoutModel>>>;
    public pptGetForQueryEditor(requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<PassePartoutModel>>>;
    public pptGetForQueryEditor(requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('PassePartoutService.pptGetForQueryEditor - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/PassePartout/for-query`;
        return this.httpClient.get<Array<PassePartoutModel>>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param searchText 
     * @param page 
     * @param perPage 
     * @param sort 
     * @param ascending 
     * @param filters 
     * @param parentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pptSearch(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<PassePartoutPagedResult>;
    public pptSearch(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<PassePartoutPagedResult>>;
    public pptSearch(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<PassePartoutPagedResult>>;
    public pptSearch(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchText !== undefined && searchText !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchText, 'SearchText');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'Page');
        }
        if (perPage !== undefined && perPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>perPage, 'PerPage');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'Sort');
        }
        if (ascending !== undefined && ascending !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ascending, 'Ascending');
        }
        if (filters !== undefined && filters !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filters, 'Filters');
        }
        if (parentId !== undefined && parentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>parentId, 'ParentId');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('PassePartoutService.pptSearch - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/PassePartout/search`;
        return this.httpClient.get<PassePartoutPagedResult>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param searchText 
     * @param page 
     * @param perPage 
     * @param sort 
     * @param ascending 
     * @param filters 
     * @param parentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pptSearchAllEvents(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<PassePartoutDetailPagedResult>;
    public pptSearchAllEvents(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<PassePartoutDetailPagedResult>>;
    public pptSearchAllEvents(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<PassePartoutDetailPagedResult>>;
    public pptSearchAllEvents(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchText !== undefined && searchText !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchText, 'SearchText');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'Page');
        }
        if (perPage !== undefined && perPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>perPage, 'PerPage');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'Sort');
        }
        if (ascending !== undefined && ascending !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ascending, 'Ascending');
        }
        if (filters !== undefined && filters !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filters, 'Filters');
        }
        if (parentId !== undefined && parentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>parentId, 'ParentId');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('PassePartoutService.pptSearchAllEvents - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/PassePartout/searchwithallevents`;
        return this.httpClient.get<PassePartoutDetailPagedResult>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param pptId 
     * @param eventId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pptValidationReport(pptId: number, eventId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<LongRunningOperationModel>;
    public pptValidationReport(pptId: number, eventId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<LongRunningOperationModel>>;
    public pptValidationReport(pptId: number, eventId: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<LongRunningOperationModel>>;
    public pptValidationReport(pptId: number, eventId: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (pptId === null || pptId === undefined) {
            throw new Error('Required parameter pptId was null or undefined when calling pptValidationReport.');
        }
        if (eventId === null || eventId === undefined) {
            throw new Error('Required parameter eventId was null or undefined when calling pptValidationReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('PassePartoutService.pptValidationReport - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/PassePartout/validationreport/${encodeURIComponent(String(pptId))}/${encodeURIComponent(String(eventId))}`;
        return this.httpClient.get<LongRunningOperationModel>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param searchText 
     * @param page 
     * @param perPage 
     * @param sort 
     * @param ascending 
     * @param filters 
     * @param parentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchForPassePartoutEvents(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<AllEventsForPassePartoutViewPagedResult>;
    public searchForPassePartoutEvents(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<AllEventsForPassePartoutViewPagedResult>>;
    public searchForPassePartoutEvents(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<AllEventsForPassePartoutViewPagedResult>>;
    public searchForPassePartoutEvents(searchText?: string, page?: number, perPage?: number, sort?: string, ascending?: boolean, filters?: { [key: string]: string; }, parentId?: number, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchText !== undefined && searchText !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchText, 'SearchText');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'Page');
        }
        if (perPage !== undefined && perPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>perPage, 'PerPage');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'Sort');
        }
        if (ascending !== undefined && ascending !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ascending, 'Ascending');
        }
        if (filters !== undefined && filters !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filters, 'Filters');
        }
        if (parentId !== undefined && parentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>parentId, 'ParentId');
        }

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('PassePartoutService.searchForPassePartoutEvents - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/PassePartout/searchforpptevents`;
        return this.httpClient.get<AllEventsForPassePartoutViewPagedResult>(`${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param passePartoutStoreArgs 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePassepartout(passePartoutStoreArgs?: PassePartoutStoreArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<Country>>;
    public updatePassepartout(passePartoutStoreArgs?: PassePartoutStoreArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<Country>>>;
    public updatePassepartout(passePartoutStoreArgs?: PassePartoutStoreArgs, requireAuthentication?: boolean, longRunningOperation?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<Country>>>;
    public updatePassepartout(passePartoutStoreArgs?: PassePartoutStoreArgs, requireAuthentication: boolean = true, longRunningOperation: boolean = false, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let isAnon = sessionStorage.getItem('jwt') === null;

        if (requireAuthentication && isAnon && environment.production == false) {
            console.error('PassePartoutService.updatePassepartout - You are calling an api method that requires authentication while no JWT token is present. Either set requiredAuthentication=false or make sure the user is logged in before calling this method.');
        }
        
        if (requireAuthentication == false) {
            localVarHeaders = localVarHeaders.set('no-auth', 'true');
        }

        if (longRunningOperation == true) {
            localVarHeaders = localVarHeaders.set('longRunningOperation', 'true')
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v2/PassePartout/updatepassepartout`;
        return this.httpClient.post<Array<Country>>(`${this.configuration.basePath}${localVarPath}`,
            passePartoutStoreArgs,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
