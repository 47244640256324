/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TicketSwapTypeEnum = 'Swap' | 'MarketplacePurchase' | 'Duplicate' | 'Assignment';

export const TicketSwapTypeEnum = {
    Swap: 'Swap' as TicketSwapTypeEnum,
    MarketplacePurchase: 'MarketplacePurchase' as TicketSwapTypeEnum,
    Duplicate: 'Duplicate' as TicketSwapTypeEnum,
    Assignment: 'Assignment' as TicketSwapTypeEnum
};

