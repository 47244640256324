/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EventTypeDetails { 
    Id?: number;
    Created?: Date | null;
    Modified?: Date | null;
    Deleted?: Date | null;
    ParentEventTypeId?: number | null;
    LandingpagePerma?: string | null;
    Name?: string | null;
    OverviewImageUrl?: string | null;
    LogoImageUrl?: string | null;
    Programme?: string | null;
    TravelDirections?: string | null;
    Active?: boolean;
    SportEvent?: boolean | null;
    EventTypeLogoId?: number | null;
    EventTypeSponsorImageId?: number | null;
    LogoImage?: string | null;
    OverviewImage?: string | null;
}

