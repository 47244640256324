/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ButtonStyle = 'Standard' | 'Accent' | 'Disabled';

export const ButtonStyle = {
    Standard: 'Standard' as ButtonStyle,
    Accent: 'Accent' as ButtonStyle,
    Disabled: 'Disabled' as ButtonStyle
};

