import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpLoaderService } from '@tymes4-shared';
import { TranslateService } from "@ngx-translate/core";
import { ContingentService, InitiativeService } from '../../api';

@Component({
  selector: 'app-initiative-form',
  templateUrl: './edit-initiative-form.component.html'
})
export class EditInitiativeFormComponent implements OnInit {
  public form: FormGroup;

  public saving = false;

  private isNew: boolean;
  private editObject: any;

  public ticketNamingOptions: any;
  public contingents: any;

  validationMessages = [];

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<EditInitiativeFormComponent>,
    private contingentService: ContingentService,
    private initiativeService: InitiativeService,
    private translate: TranslateService,
    private loader: HttpLoaderService) { }

  ngOnInit() {
    this.isNew = this.passedData.isNew;
    this.editObject = this.passedData.payload;

    if (this.editObject !== null && this.editObject.ContingentId === null) this.editObject.ContingentId = -1;


    this.form = new FormGroup({
      Id: new FormControl(null),
      Guid: new FormControl(null),
      Name: new FormControl('', [Validators.required]),
      Code: new FormControl('', [Validators.required]),
      ContingentId: new FormControl(-1),
      Private: new FormControl(false),
      Active: new FormControl(true),
      //MaxNumber: new FormControl(''),
      ActiveFrom: new FormControl(null),
      ActiveTill: new FormControl(null),
      DescriptionText: new FormControl(null),
      Named: new FormControl(false)
    });

    this.contingentService.listSelectableContingents().subscribe(data => {
      this.contingents = data;
      this.contingents.splice(0, 0, { Name:  this.translate.instant('GENERIC.DROPDOWN.NONE'), Id: -1});
    });

    if (!this.isNew) {


      setTimeout(() => {
        this.form.patchValue(this.editObject);
      }, 0);
    }
  }

  submit() {
    const propertyObject = this.form.value;

    this.saving = true;
    this.loader.open();

    if (+propertyObject.ContingentId === -1) propertyObject.ContingentId = null;

    propertyObject.MaxNumber = 0;

    // store the object and return the id
    if (this.isNew) {
      this.initiativeService.createInitiative(propertyObject).subscribe((id: number) => {
        // close this dialog and pass the newly added id to the called
        this.dialogRef.close(id);
        this.loader.close();
      });
    } else {
      this.initiativeService.updateInitiative(propertyObject).subscribe((id: any) => {
        this.dialogRef.close(true);
        this.loader.close();
      });
    }
  }
}
