/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TicketStatusAvailableActions { 
    Assignable?: boolean;
    InviteAcceptAllowed?: boolean;
    InviteDeclineAllowed?: boolean;
    Claimable?: boolean;
    DownloadAllowed?: boolean;
    ReturnAllowed?: boolean;
    RevokeAllowed?: boolean;
    InviteAllowed?: boolean;
    ResellAllowed?: boolean;
    WithdrawAllowed?: boolean;
}

