/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CustomerPassePartoutDetailsModel { 
    Id?: number;
    OrderLineId?: number;
    OrderId?: number;
    OrderCustomerId?: number;
    OrderCustomerName?: string | null;
    RowNumber?: string | null;
    SeatNumber?: string | null;
    CustomerName?: string | null;
    CustomerId?: number;
    PassePartoutName?: string | null;
    PassePartoutId?: number;
    SeasonId?: number;
    ParentSectionName?: string | null;
    IndexNumber?: number | null;
    Barcode?: string | null;
    Version?: number | null;
    Status?: number | null;
    HardCardTypeId?: number;
    HardCardTypeName?: string | null;
    HardCardStatusName?: string | null;
    CreateHardCard?: boolean;
    ExportToACL?: boolean;
    UseHardcard?: boolean | null;
    PassePartoutTypeCode?: string | null;
}

