/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CustomerMergeDetailView { 
    Id?: number;
    CustomerNumber?: string | null;
    FirstName?: string | null;
    LastName?: string | null;
    DateOfBirth?: Date | null;
    Street?: string | null;
    StreetNr?: number;
    City?: string | null;
    PostalCode?: string | null;
    Email?: string | null;
    RecentOrders?: Array<string> | null;
}

