/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TicketEntryForStatus { 
    Id?: number;
    Active?: boolean;
    Barcode?: string | null;
    Created?: Date;
    EventDateTime?: Date;
    EventName?: string | null;
    SectionNr?: string | null;
    SectionName?: string | null;
    SectionShortName?: string | null;
    Row?: string | null;
    Seat?: string | null;
    CustomerNr?: string | null;
    Product?: string | null;
    CustomerName?: string | null;
    Reason?: string | null;
}

