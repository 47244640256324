<!-- MODAL HEADER-->
<div class="row modal-bar">

    <div class="col-sm-6">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>

    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
</div>


<form [formGroup]="form" class="form-content">
    <div class="section-wrapper">
      <div class="form-layout">

        <div class="row ">

          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label">{{ 'GENERIC.EDITDIALOG.NAME' | translate}}<span class="tx-danger">*</span></label>
              <input
                class="form-control"
                name="Name"
                autocomplete="off"
                formControlName="Name"
                placeholder="{{ 'INITIATIVE.EDITDIALOG.PLACEHOLDER.NAMEINITIATIVE' | translate}}">
              <t4-display-validation controlName="Name" [form]="form"
                [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label">{{ 'GENERIC.EDITDIALOG.CODE' | translate}}<span class="tx-danger">*</span></label>
              <input
                class="form-control"
                trim
                name="Code"
                autocomplete="off"
                formControlName="Code"
                placeholder="{{ 'INITIATIVE.EDITDIALOG.PLACEHOLDER.CODEINITIATIVE' | translate}}">
              <t4-display-validation controlName="Code" [form]="form"
                [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>
        </div>

        <div class="row">

          <div class="col-12">
            <div class="form-group">
              <label class="form-control-label">
                {{ 'INITIATIVE.EDITDIALOG.LIMITSALESCONTIGENT' | translate}}
              </label>
              <ng-select [items]="contingents" bindLabel="Name" bindValue="Id" placeholder="{{ 'INITIATIVE.EDITDIALOG.PLACEHOLDER.LIMITSALESCONTIGENT' | translate}}" formControlName="ContingentId"
                         [clearable]="false" notFoundText="{{ 'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <t4-date-time-control formControlName="ActiveFrom" [required]="false" [beforeToday]="false" [labelDate]="'INITIATIVE.EDITDIALOG.ACTIVEFROM.DATE'" [labelTime]="'INITIATIVE.EDITDIALOG.ACTIVEFROM.TIME'"></t4-date-time-control>
              <t4-display-validation controlName="ActiveFrom" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <t4-date-time-control formControlName="ActiveTill" [required]="false" [beforeToday]="false" [labelDate]="'INITIATIVE.EDITDIALOG.ACTIVETILL.DATE'" [labelTime]="'INITIATIVE.EDITDIALOG.ACTIVETILL.TIME'"></t4-date-time-control>
              <t4-display-validation controlName="ActiveTill" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3">
            <label class="ckbox ckbox-input">
              <input type="checkbox" name="Named" formControlName="Named">
              <span class="ml-1">{{ 'GENERIC.EDITDIALOG.NAMEDCARDS' | translate}}</span>
            </label>
          </div>

          <div class="col-lg-3">
            <label class="ckbox ckbox-input">
              <input
                type="checkbox"
                name="Active"
                formControlName="Active">
              <span class="ml-1">{{ 'GENERIC.EDITDIALOG.ACTIVE' | translate}}</span>
            </label>
          </div>
        </div>

        <!-- <div class="row">



          <div class="form-group col-3">
            <label class="ckbox ckbox-input">
              <input
                type="checkbox"
                name="Private"
                formControlName="Private">
              <span class="ml-1">Besloten</span>
            </label>
          </div>

        </div> -->



        <div class="form-layout-footer row">
          <div class="col-6">
            <button [disabled]="!form.valid || saving" (click)="submit()" class="btn btn-block btn-primary bd-0"><i
              class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
          </div>
          <div class="col-6">
            <button (click)="dialogRef.close(false)" class="btn btn-block btn-secondary bd-0"><i
              class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
          </div>
        </div><!-- form-layout-footer -->
      </div><!-- form-layout -->
    </div>
</form>
