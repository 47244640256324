/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NotificationModel { 
    Created?: Date;
    DetailText?: string | null;
    Dismissable?: boolean;
    HasAttachment?: boolean;
    LastStatusUpdated?: Date;
    LongRunningOperationId?: number | null;
    ProgressPercentage?: number | null;
    TranslationCode?: string | null;
    Viewed?: boolean;
    LROIsPending?: boolean;
    LROIsQueued?: boolean;
    CanBeCanceled?: boolean;
    NotificationTypeId?: number;
    NotificationId?: number;
    NotificationTypeTranslationCode?: string | null;
    NotificationStatusTranslationCode?: string | null;
    NotificationStatusCode?: string | null;
    HasAdditionalData?: boolean;
    AllowsContinuation?: boolean;
}

