/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PassePartoutEventQueueStatusView { 
    Id?: number;
    LroId?: number | null;
    Created?: Date;
    EventId?: number;
    PassePartoutId?: number;
    PassePartoutName?: string | null;
    PassePartoutCode?: string | null;
    ProgressPercentage?: number | null;
    UserName?: string | null;
    LastStatusUpdate?: Date | null;
    Message?: string | null;
    OperationStatusId?: number | null;
    HasSucceeded?: boolean;
    IsRunning?: boolean;
    IsDeletion?: boolean;
    IsQueued?: boolean;
    HasReportBinary?: boolean;
    Failed?: boolean;
}

